import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import { RichText } from "prismic-reactjs"
import Seo from "../components/Seo"

const ArticleTemplate = ({ data }) => {
  const title = data.prismicArticles.data.article_title.text
  const articleImage =
    data.prismicArticles.data.article_hero_image.localFile.childImageSharp.fluid
  const articleBody = data.prismicArticles.data.articles_body.raw
  const pageUrl = `https://kuuubo.com/articles/${title}`
  const divStyle = {
    gridTemplateColumns: "1fr min(70ch, 100%) 1fr",
  }
  const pageDesc = data.prismicArticles.data.articles_body.text
    .split(" ")
    .slice(0, 100)
    .join(" ")
  const articleDate = data.prismicArticles.date
  return (
    <Layout>
      <Seo
        title={`KUBO | ${title}`}
        description={pageDesc}
        image={articleImage}
        author={`KUBO`}
        articleDate={articleDate}
        articleBody={data.prismicArticles.data.articles_body.text}
        articleUrl={pageUrl}
        isArticle={true}
      />
      <div className="articlePage">
        <div className="articlePage-header mb-48">
          <div className="articlePage-header--title">
            <h1 className="pageHeadings text-green">{title}</h1>
          </div>
          <div className="articlePage-header--image">
            <Image className="scale-image" fluid={articleImage} />
          </div>
        </div>
        <section className="articlePage--body" style={divStyle}>
          <RichText render={articleBody} />
        </section>
      </div>
      <div className="spNextProject">
        <div className="projectBtn">
          <Link to="/articles" className="glitch" data-word="all Articles">
            All Articles
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query GetArticles($slug: String!) {
    prismicArticles(uid: { eq: $slug }) {
      date: first_publication_date(formatString: "DD-MM-YYYY")
      data {
        article_hero_image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        article_title {
          text
        }
        articles_body {
          text
          raw
        }
      }
    }
  }
`
